<template>
  <ion-page>
    <page-header title="Orte" />
    <ion-content :fullscreen="true">
      <div class="content">

        <iframe
          class="map"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src="https://www.openstreetmap.org/export/embed.html?bbox=13.201789855957033%2C52.46364492160561%2C13.40435028076172%2C52.570716335889344&amp;layer=mapnik">
        </iframe>

      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonContent } from '@ionic/vue';
import PageHeader from '@/components/PageHeader.vue'

export default  {
  name: 'TabLocations',
  components: { IonContent, IonPage, PageHeader }
}
</script>

<style scoped>
.map {
  width: 100%;
  height: 89.3vh;
}
</style>
